import { useState, useEffect, useRef, useCallback } from 'react';
import logger from './logger';
import { getDocument } from './getBrowserGlobals';

type UseControlledProperties<Value> = {
  /**
   * The value that's passed in as the component's `value`, making it a controlled component
   */
  controlledValue?: Value;
  /**
   * The default value passed as the component's `defaultValue`, making it an uncontrolled component
   */
  defaultValue?: Value;
  /**
   * A unique name that's displayed in warnings
   * @example 'Accordion.isExpanded'
   */
  name: string;
};

/**
 * A Hook allowing a component to be either controlled or uncontrolled
 * depending on the values passed to it. Warns if a component tries to
 * flip between controlled/uncontrolled.
 */
export const useControlled = <Value>({
  controlledValue,
  defaultValue,
  name,
}: UseControlledProperties<Value>): [
  Value | undefined,
  (newValue: Value) => void,
] => {
  // Calculate whether or not the component is controlled by checking if the
  // controlledValue has been defined. We store it in a ref so we can
  // keep track of the original controlled state to warn if it changes
  // over time.
  const { current: origIsControlled } = useRef(controlledValue !== undefined);

  const [valueFromState, setValue] = useState(defaultValue);

  // If the component is controlled, then we'll use the controlledValue
  // passed in. Otherwise, it's uncontrolled so we'll use the internally
  // maintained value
  const value = origIsControlled ? controlledValue : valueFromState;

  // The code below debugging will not be run in production and in fact
  // should be eliminated when Webpack builds the production bundle
  if (process.env.NODE_ENV !== 'production') {
    // NOTE: it looks like `useEffect` is being called conditionally, but
    // this gets handled at build-time not at runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // If the controlled state changes in either direction
      // (controlled -> uncontrolled or uncontrolled -> controlled)
      // that is a problem and can cause bugs. The code below
      // warns of this happening.
      if (origIsControlled !== (controlledValue !== undefined)) {
        logger.warn(
          `"${name}" is changing from ${
            origIsControlled
              ? 'uncontrolled to controlled'
              : 'controlled to uncontrolled'
          }. Elements should not switch from uncontrolled to controlled (or vice versa). Decide between making "${name}" controlled or uncontrolled for the lifetime of the component. More info: https://fb.me/react-controlled-components.`,
        );
      }
    }, [controlledValue, origIsControlled, name]);

    // We keep a ref of the original defaultValue so we can check
    // below if the current defaultValue differs from the original.
    // Changing the default won't actually have an effect, which
    // will probably be confusing. They probably want a controlled
    // component.
    // this gets handled at build-time not at runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { current: origDefaultValue } = useRef(defaultValue);

    // this gets handled at build-time not at runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (defaultValue !== origDefaultValue) {
        logger.warn(
          `"${name}" is changing its defaultValue after being initialized. To suppress this warning, make "${name}" a controlled component.`,
        );
      }
    }, [defaultValue, origDefaultValue, name]);
  }

  // We only want to set the internal value state if the component is
  // uncontrolled, so we need to wrap `setValue` in a function to do
  // the check. However, we don't want to create a new function every
  // time this hook is executed, so `useCallback` allows us to make
  // one "memoized" version that never changes.
  const setValueIfUncontrolled = useCallback(
    (newValue: Value) => {
      if (!origIsControlled) {
        setValue(newValue);
      }
    },
    [origIsControlled],
  );

  return [value, setValueIfUncontrolled];
};

/**
 * Listens for clicks and calls the callback if a click is made outside the
 * specified element. Supports mouse and touch events.
 * @param elementOrElements - Element(s) to track clicking outside of.
 * @param callback - Code to run when a click is made outside the element. This
 *  should probably be wrapped in `useCallback` to avoid unnecessary processing.
 * @param supportedEvents - Determines what events to track for outside clicks.
 * Set to `mouse` to only track mouse events. Set to `touch` to onlly track
 * touch events. Set to `all` (the default) to track both.
 */
// This hook is tested via tests for the Tooltip component, which uses it.
type UseOnClickOutsideElement = Element | null;

export const useOnClickOutside = (
  elementOrElements: UseOnClickOutsideElement | UseOnClickOutsideElement[],
  callback?: (event: MouseEvent | TouchEvent) => void,
  supportedEvents: 'mouse' | 'touch' | 'all' = 'all',
) => {
  const elements = (
    Array.isArray(elementOrElements) ? elementOrElements : [elementOrElements]
  ).filter((element): element is Element => !!element);

  useEffect(() => {
    if (!callback) {
      // No callback set. Do nothing.
      return undefined;
    }

    const onClickOutsideListener = (event: MouseEvent | TouchEvent) => {
      if (!elements) return;

      const hasClickedWithin = elements.some(element =>
        element.contains(event.target as Node),
      );

      // if we've clicked within any one of the elements, then we don't want to trigger the callback
      if (hasClickedWithin) {
        return;
      }

      callback?.(event);
    };

    const supportMouseEvents =
      supportedEvents === 'mouse' || supportedEvents === 'all';
    const supportTouchEvents =
      supportedEvents === 'touch' || supportedEvents === 'all';

    if (supportMouseEvents) {
      getDocument()?.addEventListener('mousedown', onClickOutsideListener);
    }
    if (supportTouchEvents) {
      getDocument()?.addEventListener('touchstart', onClickOutsideListener);
    }

    return () => {
      if (supportMouseEvents) {
        getDocument()?.removeEventListener('mousedown', onClickOutsideListener);
      }
      if (supportTouchEvents) {
        getDocument()?.removeEventListener(
          'touchstart',
          onClickOutsideListener,
        );
      }
    };
  }, [elements, callback, supportedEvents]);
};

/**
 * Focuses on first interactive element when a Portal is added to the page.
 * Useful for components that use Portals, and when opened we want
 * to focus inside of the Portal, ie: Pointers and Sheets
 * @param focusOnOpen - Should the element focus on open
 * @param isOpen - Code to run when a click is made outside the element. This
 * @param elementToFocus - Element focus should be transitioned to
 * Tested via the Pointer and Sheet e2e tests
 */
export const useFocusOnOpen = (
  focusOnOpen: boolean,
  isOpen: boolean,
  elementToFocus: HTMLElement | null,
) => {
  const [isElementReady, setIsElementReady] = useState(false);
  const previouslyActiveElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!isOpen || !focusOnOpen || !isElementReady || !elementToFocus) {
      return;
    }

    const activeElement = getDocument()?.activeElement;

    if (activeElement && activeElement instanceof HTMLElement) {
      // Save the currently active element. We need to return focus to it on
      // close.
      previouslyActiveElement.current = activeElement;
    }

    const firstFocusableEl: HTMLElement | null =
      elementToFocus.querySelector('a, button, input');

    firstFocusableEl?.focus();
  }, [focusOnOpen, isOpen, isElementReady, elementToFocus]);

  useEffect(() => {
    if (!isOpen) {
      if (previouslyActiveElement.current) {
        // If we moved focus to the pointer on open, move it back to the
        // previously focused element on close.
        previouslyActiveElement?.current.focus();
      }

      setIsElementReady(false);
    }
  }, [isOpen, previouslyActiveElement]);

  // Needed for focusOnOpen. If we try to focus before the element is finished
  // positioning a portal-based element, it can lead to the screen scrolling to
  // wherever the element is before, which is often away
  // from where the component will be once it is positioned.
  const onElementReady = useCallback(() => {
    setIsElementReady(true);
  }, [setIsElementReady]);

  return onElementReady;
};
